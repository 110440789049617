<template>
  <b-row>
    <b-col md="3" sm="4" class="my-1">
      <b-form-group class="mb-0"> 
        <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" class="w-50" />
      </b-form-group>
    </b-col>
    <b-col md="3" sm="8" class="my-1">
      
    </b-col>
    <b-col md="6" class="my-1">
      <b-form-group label="" label-cols-sm="3" label-align-sm="right" label-size="sm" label-for="filterInput"
        class="mb-0">
        <b-input-group size="sm">
          <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Type to Search" />
          <b-input-group-append>
            <b-button :disabled="!filter" @click="filter = ''">
              Clear
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
    </b-col>

    <b-col cols="12">
      <b-table striped hover responsive :per-page="perPage" :current-page="currentPage" :items="items" :fields="fields"
        :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" :filter="filter"
        :filter-included-fields="filterOn" @filtered="onFiltered">
        <template #cell(id)="data">
     {{ data.id }}
     <b-row>
      <b-col md="4">
        <small>Program </small>
        <h6><small><strong>1.03.10 PROGRAM PENYELENGGARAAN JALAN </strong></small></h6>
        <small>Kegiatan </small>
        <h6><small><strong>1.03.10.1.01 Penyelenggaraan Jalan Provinsi  </strong></small></h6>
      </b-col>
      <b-col md="5"> 
        <small>Sub Kegiatan  </small>
        <h6><small><small>1.03.10.1.01.0041   Penyusunan Rencana, Kebijakan, Strategi dan Teknis Pengembangan Jaringan Jalan serta Perencanaan Teknis Penyelenggaraan Jalan dan Jembatan</small></small></h6>
     <h6><small>Indikator Sub Kegiatan</small></h6>
     <h6><small>Jumlah Dokumen Hasil Pelaksanaan Advis dan Layanan Teknis, Kajian Kebijakan, Bantuan Teknis, Bimbingan Teknis, Pengelolaan Pengendalian (Dokumen)
</small></h6>
      </b-col>
      <b-col md="3">
<small>Capaian Kinerja Program </small>
<h6>100%</h6>
<small>Kerangka Pendanaan</small>
<h5>Rp.  2.652.062.000  </h5>
      </b-col>
     </b-row>
        </template>
 
      </b-table>
    </b-col>

    <b-col cols="12">
      <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="right" size="sm"
        class="my-0" />
    </b-col>
  </b-row>
</template>

<script>
  import {
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
  } from 'bootstrap-vue'

  export default {
    components: {
      BTable,
      BAvatar,
      BBadge,
      BRow,
      BCol,
      BFormGroup,
      BFormSelect,
      BPagination,
      BInputGroup,
      BFormInput,
      BInputGroupAppend,
      BButton,
    },
    data() {
      return {
        perPage: 5,
        pageOptions: [3, 5, 10],
        totalRows: 1,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [{
            key: 'id',
            label: '', }
        ],
        items: [{
            id: 1,
            // eslint-disable-next-line global-require
            avatar: require('@/assets/images/avatars/10-small.png'),
            full_name: "Korrie O'Crevy",
            post: 'Nuclear Power Engineer',
            email: 'kocrevy0@thetimes.co.uk',
            city: 'Krasnosilka',
            start_date: '09/23/2016',
            salary: '$23896.35',
            age: '61',
            experience: '1 Year',
            status: 2,
          },
          
        ], 
      }
    },
    computed: {
      sortOptions() {
        // Create an options list from our fields
        return this.fields
          .filter(f => f.sortable)
          .map(f => ({
            text: f.label,
            value: f.key
          }))
      },
    },
    mounted() {
      // Set the initial number of items
      this.totalRows = this.items.length
    },
    methods: {
      info(item, index, button) {
        this.infoModal.title = `Row index: ${index}`
        this.infoModal.content = JSON.stringify(item, null, 2)
        this.$root.$emit('bv::show::modal', this.infoModal.id, button)
      },
      resetInfoModal() {
        this.infoModal.title = ''
        this.infoModal.content = ''
      },
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length
        this.currentPage = 1
      },
    },
  }
</script>