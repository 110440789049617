<template>
  <section id="card-style-variation">

    <!-- outline color -->

    <b-row>
      <div class="mt-5"></div>
      <b-col md="4">
        <router-link to="/kegiatan_renstra">
          <statistic-card-vertical icon="LayersIcon" :statistic="dataStatstik.aktif_hari_ini" statistic-title="Renstra"
            color="primary" />
        </router-link>
      </b-col>
      <b-col md="4">
        <router-link to="/renja_set_up">
          <statistic-card-vertical icon="GridIcon" :statistic="dataStatstik.kelompok_aktif" statistic-title="Renja"
            color="danger" />
        </router-link>
      </b-col>
      <b-col md="4">
        <router-link to="/usulan_kegiatan">
          <statistic-card-vertical icon="TagIcon" :statistic="dataStatstik.user_aktif" statistic-title="Usulan Kegiatan"
            color="info" />
        </router-link>
      </b-col>


      <b-col md="8">
        <router-link to="/perhitungan_ruc">
          <statistic-card-vertical icon="GiftIcon" :statistic="dataStatstik.kordinaat_aktif"
            statistic-title="Perhitungan RUC " color="warning" />

        </router-link>
      </b-col>

    </b-row>

    <!-- <b-row>
      <b-col md="12" xl="12">
        <b-card border-variant="primary" title="Presensi Hari ini" bg-variant="" class="shadow-none">
          <b-card-text>
            menginfokan jumlah user yang melakian presensi hari ini sesuai dengan titik kordinat
          </b-card-text>
        </b-card>
      </b-col>
 

    </b-row> -->
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardText,
  BCardTitle,
} from 'bootstrap-vue'
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'
export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardTitle,
    StatisticCardVertical
  },
  props: {
    dataStatstik: {}
  },
  data() {
    return {
      solidColor: [{
        bg: 'primary',
        title: 'Primary card title'
      },
      {
        bg: 'secondary',
        title: 'Secondary card title'
      },
      {
        bg: 'success',
        title: 'Success card title'
      },
      {
        bg: 'danger',
        title: 'Danger card title'
      },
      {
        bg: 'warning',
        title: 'Warning card title'
      },
      {
        bg: 'info',
        title: 'Info card title'
      },
      ],
    }
  },
}
</script>
