<template>
    <b-card
      no-body
      class="card-employee-task"
    >
      <b-card-header>
        <b-card-title>Presensi Hari Ini</b-card-title>
        <feather-icon
          icon="MoreVerticalIcon"
          size="18"
          class="cursor-pointer"
        />
      </b-card-header>
  
      <!-- body -->
      <b-card-body>
        <div
          v-for="(employee) in employeeData"
          :key="employee.userFullName"
          class="employee-task d-flex justify-content-between align-items-center"
        >
          <b-media no-body>
            <b-media-aside class="mr-75"> 
              <b-avatar
              :text="employee.avatar"
                rounded
                size="42" 
                :variant="employee.variant"
              />
            </b-media-aside>
            <b-media-body class="my-auto">
              <h6 class="mb-0">
                {{ employee.userFullName }}
              </h6>
              <small>{{ employee.designation }}</small>
            </b-media-body>
          </b-media>
          <div class="d-flex align-items-center">
            <h6 class="text-info mr-75">{{ employee.duration }}</h6>
   
          </div>
        </div>
      </b-card-body>
    </b-card>
  </template>
  
  <script>
  import {
    BCard, BCardTitle, BCardHeader, BCardBody, BMedia, BMediaAside, BAvatar, BMediaBody,
  } from 'bootstrap-vue'
  import VueApexCharts from 'vue-apexcharts'
  import { $themeColors } from '@themeConfig'
  /* eslint-disable global-require */
  const $trackBgColor = '#e9ecef'
  export default {
    components: {
      BCard,
      BCardTitle,
      BCardHeader,
      BCardBody,
      BMedia,
      BMediaAside,
      BMediaBody,
      BAvatar,
      VueApexCharts,
    },
    data() {
      return {
        chartData: [],
        chartColor: [$themeColors.primary, $themeColors.danger, $themeColors.success, $themeColors.secondary, $themeColors.warning, $themeColors.primary],
        chartSeries: [45, 65, 60, 35, 65, 80],
        employeeData: [
          {
            avatar: 10,
            userFullName: 'Tepat Waktu',
            designation: 'User yang melakukan presensi tepat waktu',
            variant: 'light-primary',
          },
          {
            avatar: 5,
            userFullName: 'Terlambat',
            designation: 'User yang terlambat melakukan prsensi',
            variant: 'light-warning',
          }, 
          {
            avatar: 100,
            userFullName: 'Terlambat',
            designation: 'User yang tidak melakukan presensi',
            variant: 'light-danger',
          }, 
        ],
        chart: {
          series: [65],
          options: {
            grid: {
              show: false,
              padding: {
                left: -15,
                right: -15,
                top: -12,
                bottom: -15,
              },
            },
            colors: [$themeColors.primary],
            plotOptions: {
              radialBar: {
                hollow: {
                  size: '22%',
                },
                track: {
                  background: $trackBgColor,
                },
                dataLabels: {
                  showOn: 'always',
                  name: {
                    show: false,
                  },
                  value: {
                    show: false,
                  },
                },
              },
            },
            stroke: {
              lineCap: 'round',
            },
          },
        },
      }
    },
    created() {
      for (let i = 0; i < this.employeeData.length; i += 1) {
        const chartClone = JSON.parse(JSON.stringify(this.chart))
        chartClone.options.colors[0] = this.chartColor[i]
        chartClone.series[0] = this.chartSeries[i]
        this.chartData.push(chartClone)
      }
    },
  }
  /* eslint-disable global-require */
  </script>
  