<template>
  <div>
    <b-row>
      <b-col md="12">
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col md="4">

        <!-- <h2 class="text-primary text-center" style="margin-bottom: -30px;">Selamat datang di aplikasi </h2> -->
        <div class="ml-2"> <img src='@/assets/images/pages/orang2.png' width="100%"
            class="img-responsive img-rounded mb-1" /></div>
        <!-- <img src='@/assets/images/pages/logo_siaga_bima.png' width="100%" class="img-responsive img-rounded mb-1" /> -->
        <!-- <kelompok></kelompok>  -->

      </b-col>
      <b-col md="8">
        <div v-for="periode_anggaran in periode_anggaran" v-bind:key="periode_anggaran">
          <b-alert variant="primary" show v-show="periode_anggaran.status == 'All'">
            <div class="alert-body">
              <span><strong>{{ periode_anggaran.judul }} </strong>
                <h6>Tanggal Mulai {{ periode_anggaran.mulai }} - {{ periode_anggaran.selesai }}</h6>
              </span>
            </div>
          </b-alert>
          <b-alert variant="warning" show v-show="periode_anggaran.status != 'All'">
            <div class="alert-body">
              <span><strong>{{ periode_anggaran.judul }}<small> Periode terbatas </small></strong>
                <h6>Tanggal Mulai {{ periode_anggaran.mulai }} - {{ periode_anggaran.selesai }}
                </h6>
                <p></p>
              </span>
            </div>
          </b-alert>
        </div>
        <presensi :dataStatstik=dataStatstik class="mt-1"></presensi>
      </b-col>

    </b-row>
  </div>
</template>

<script>
import axios from '@/config/Axios';
import Base from '@/config/Mixins_base';
import kelompok from './kelompok.vue';
import peta from './peta.vue';
import presensi from './Presensi.vue';
import kelompokKurangbaik from './kelompokKurangbaik.vue';
import kelompokTerbaik from './kelompokTerbaik.vue';
import rekapPresensi from './rekapPresensi.vue';
import {
  BRow,
  BCol,
  BButton,
  BCard,
  BFormSelect, BAlert
} from 'bootstrap-vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue';

export default {
  components: {
    BRow, BAlert,
    BCol,
    BButton,
    BCard,
    peta,
    presensi,
    BFormSelect,
    kelompok,
    kelompokKurangbaik,
    kelompokTerbaik,
    rekapPresensi,
    FeatherIcon
  },
  mixins: [Base],
  data() {
    return {
      dataStatstik: {},
      mapsData: [],
      periode_anggaran: {},
      selected: 'BPJ Semarang',
      options: [{
        value: null,
        text: 'Bidang Rancang Bangun dan Pengawasan'
      },
      {
        value: 'a',
        text: 'This is First option'
      },
      {
        value: 'b',
        text: 'Simple Option'
      },
      {
        value: {
          C: '3PO'
        },
        text: 'This is an option with object value'
      },
      {
        value: 'd',
        text: 'This one is disabled',
        disabled: true
      },
      ],
    }
  },
  mounted() {
    this.get_periode_anggaran();

  },
  methods: {
    async get_periode_anggaran() {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/dassboard/info',
        data: {

        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.periode_anggaran = response.data.result;

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
    async load_statistik() {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/dashboard/statistik/load_data',
        data: {

        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.dataStatstik = response.data.result;

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
    async maps() {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/dashboard/statistik/maps',
        data: {

        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.mapsData = response.data.result;

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
  }

}
</script>

<style></style>